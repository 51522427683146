<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="10" sm="8" md="6" lg="4">
        <v-card>
          <v-toolbar :color="actionColor" dark>
            <v-toolbar-title>{{ $helper.tRoute($route.name) }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="$router.replace({ name: 'home' })">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text>
            <div class="text--primary">{{ $t("Auth.notifyText") }}</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="notify" :color="actionColor" id="resendVerification" dark text
              >{{ $t("Auth.resendVerification") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "EmailNotify",

  data() {
    return {
      actionColor: this.$settings.appColor,
      loading: false,
    };
  },

  methods: {
    ...mapActions({
      authNotify: "auth/notify",
      alertError: "app/alertError",
      alertSuccess: "app/alertSuccess",
    }),

    notify() {
      this.loading = true;

      this.authNotify()
        .then(() => {
          this.alertSuccess(this.$t("Auth.verificationResent"));
          this.$router.replace({ name: "home" });
        })
        .catch((e) =>
          this.alertError(this.$t("Auth.notifyError") + this.$helper.error(e))
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>
